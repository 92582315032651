import { createApp } from 'vue'
import svgSymbolSpriteLoader from 'svg-symbol-sprite-loader/src/browser/icon-sprite-loader'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createDatabase, startReplication } from './database'
import './main.css'
import { databaseKey } from "@/injectionKeys"
import * as Sentry from "@sentry/vue"
import { useLocalSignedInUser } from "@/services/account.service"

declare global {
  interface Window { database: any; }
}

svgSymbolSpriteLoader({
  customSpriteId: `/icon-sprite.svg`, // FIXME
  useCache: process.env.NODE_ENV === `production`,
});

(async () => {
  const database = await createDatabase()
  window.database = database

  const localSignedInUser = useLocalSignedInUser().value
  if (localSignedInUser) {
    startReplication(localSignedInUser)
  }

  const app = createApp(App)

  if (process.env.NODE_ENV === `production`) {
    Sentry.init({
      app,
      dsn: `https://082b8d66849c47d6ac04354ba9a8e55a@o4505482772807680.ingest.sentry.io/4505482782441472`,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }

  app
    .provide(databaseKey, database)
    .use(router)
    .mount(`#app`)
})()
