import { createRouter, createWebHistory, NavigationGuardNext, RouteLocation, RouteLocationNormalized } from 'vue-router'
import format from 'date-fns/format'
import Categories from '@/components/Categories/Categories.vue'
import Data from '@/components/Data/Data.vue'
import Expense from '@/components/Expense/Expense.vue'
import Overview from '@/components/Overview/Overview.vue'
import SignIn from '@/components/SignIn/SignIn.vue'
import Route from '@/constants/Route'
import NotFound from "@/components/NotFound/NotFound.vue"
import { fetchSession } from "@/services/account.service"
import Legal from "@/components/Legal/Legal.vue"

const formatDateForOverviewRoute = (date: Date): string => format(date, `yyyy-MM`)

const routes = [
  {
    path: `/`,
    name: Route.Start,
    redirect: () => {
      return {
        name: Route.MonthOverview,
        params: {
          yearMonth: formatDateForOverviewRoute(new Date()),
        },
      }
    },
  },
  {
    path: `/:yearMonth(\\d{4}-\\d{2})`,
    name: Route.MonthOverview,
    component: Overview,
    props: (route: RouteLocation) => ({ yearMonth: route.params.yearMonth }),
    meta: { title: `Expenses` },
  },
  {
    path: `/expense/new`,
    name: Route.ExpenseAdd,
    component: Expense,
    meta: { title: `Add Expense` },
  },
  {
    path: `/expense/:expenseId`,
    name: Route.ExpenseEdit,
    component: Expense,
    props: (route: RouteLocation) => ({ expenseId: route.params.expenseId }),
    meta: { title: `Edit Expense` },
  },
  {
    path: `/categories`,
    name: Route.Categories,
    component: Categories,
    meta: { title: `Categories` },
  },
  {
    path: `/data`,
    name: Route.Data,
    component: Data,
    meta: { title: `Data` },
  },
  {
    path: `/sign-in`,
    name: Route.SignIn,
    component: SignIn,
    meta: { title: `Sign In` },

    /* Redirect when already logged in */
    async beforeEnter (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      try {
        await fetchSession()
        await next({ name: Route.Start })
      } catch (error) {
        next()
      }
    },
  },
  {
    path: `/legal`,
    name: Route.Legal,
    component: Legal,
    meta: { title: `Impressum und Datenschutz` },
  },
  {
    path: `/:pathMatch(.*)*`,
    name: Route.NotFound,
    component: NotFound,
    meta: { title: `Not Found` },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
export { formatDateForOverviewRoute }
