enum Route {
  Start = `START`,
  MonthOverview = `MONTH_OVERVIEW`,
  ExpenseAdd = `EXPENSE_ADD`,
  ExpenseEdit = `EXPENSE_EDIT`,
  Categories = `CATEGORIES`,
  Data = `DATA`,
  SignIn = `SIGN_IN`,
  NotFound = `NOT_FOUND`,
  Legal = `LEGAL`,
}

export default Route
