import axios from 'axios'
import { useLocalStorage } from "@vueuse/core"

const LOCAL_SIGNED_IN_USER_LOCAL_STORAGE_KEY = `signedInUser`

const localSignedInUser = useLocalStorage<string | null>(LOCAL_SIGNED_IN_USER_LOCAL_STORAGE_KEY, null)

const signIn = async ({ username, password }: { username: string, password: string }) => {
  const params = new URLSearchParams()
  params.append(`name`, username)
  params.append(`password`, password)

  await axios.post(`${process.env.VUE_APP_COUCH_URI}_session`, params, {
    headers: {
      'Content-Type': `application/x-www-form-urlencoded`,
    },
    withCredentials: true,
  })
  localSignedInUser.value = username
}

const fetchSession = async () => {
  const { data } = await axios.get(`${process.env.VUE_APP_COUCH_URI}_session`, {
    withCredentials: true,
  })

  if (!data.info.authenticated) {
    return Promise.reject(new Error(`Not authenticated`))
  }

  return data
}

const removeLocalSignedInUser = () => {
  localSignedInUser.value = null
}

const signOut = async () => {
  await axios.delete(`${process.env.VUE_APP_COUCH_URI}_session`, {
    withCredentials: true,
  })
  removeLocalSignedInUser()
}

const useLocalSignedInUser = () => localSignedInUser

export {
  signIn,
  fetchSession,
  signOut,
  useLocalSignedInUser,
  removeLocalSignedInUser,
}
