import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  toTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from "rxdb"

const schemaLiteral = {
  title: `Expense schema`,
  version: 0,
  primaryKey: `id`,
  type: `object`,
  properties: {
    id: {
      type: `string`,
      maxLength: 36,
    },
    amount: {
      type: `number`,
      minimum: 0,
    },
    comment: {
      type: `string`,
    },
    category: {
      ref: `categories`,
      type: `string`,
    },
    date: {
      type: `string`,
      format: `date`,
    },
  },
  indexes: [
    `category`,
  ],
  required: [
    `id`,
    `amount`,
    `category`,
    `date`,
  ],
} as const

const schemaTyped = toTypedRxJsonSchema(schemaLiteral)

export type Expense = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>
export type ExpenseDocument = RxDocument<Expense>
export type ExpenseCollection = RxCollection<Expense>
export default schemaLiteral as RxJsonSchema<Expense>
