import { createRxDatabase, addRxPlugin, RxCollection, RxDatabase } from 'rxdb'
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie'
import expense, { ExpenseCollection } from '@/schemas/expense'
import category, {
  CategoryCollection,
  CategoryDocument,
  CategoryMethods,
  CategoryStatics,
} from '@/schemas/category'
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder'
import { replicateCouchDB } from "rxdb/plugins/replication-couchdb"
import { RxReplicationState } from "rxdb/dist/types/plugins/replication"
import { removeLocalSignedInUser } from "@/services/account.service"
import router from "@/router"
import Route from "@/constants/Route"

type DatabaseCollections = {
  categories: CategoryCollection,
  expenses: ExpenseCollection,
}
export type Database = RxDatabase<DatabaseCollections>

addRxPlugin(RxDBQueryBuilderPlugin)

const SCHEMA_NAME_CATEGORIES = `categories`
const SCHEMA_NAME_EXPENSES = `expenses`

let database: Database
let replicationStates: RxReplicationState<any, any>[]

const createDatabase = async () => {
  database = await createRxDatabase<DatabaseCollections>({
    name: `coins`,
    storage: getRxStorageDexie(),
  })

  const categoryMethods: CategoryMethods = {
    async getExpenses (this: CategoryDocument) {
      return await database.expenses.find().where(`category`).eq(this.id).exec()
    },
    async getExpenseCount (this: CategoryDocument) {
      return (await this.getExpenses()).length
    },
  }

  const categoryStatics: CategoryStatics = {
    async getSortedCategories () {
      const categories: CategoryDocument[] = await database.categories.find().exec()

      const categoryIdToExpenseCount = new Map<string, number>()

      for (const category of categories) {
        categoryIdToExpenseCount.set(category.id, await category.getExpenseCount())
      }

      return [...categories].sort((categoryA, categoryB) => {
        const categoryAExpenseCount = categoryIdToExpenseCount.get(categoryA.id) ?? 0
        const categoryBExpenseCount = categoryIdToExpenseCount.get(categoryB.id) ?? 0

        if (categoryAExpenseCount > categoryBExpenseCount) {
          return -1
        }
        if (categoryAExpenseCount < categoryBExpenseCount) {
          return 1
        }
        if (categoryA.id > categoryB.id) {
          return -1
        }
        if (categoryA.id < categoryB.id) {
          return 1
        }

        return 0
      })
    },
  }

  await database.addCollections({
    [SCHEMA_NAME_EXPENSES]: {
      schema: expense,
    },
    [SCHEMA_NAME_CATEGORIES]: {
      schema: category,
      methods: categoryMethods,
      statics: categoryStatics,
    },
  })

  return database
}

const replicateCollection = (collection: RxCollection, username: string) => {
  const replicationState = replicateCouchDB(
    {
      collection,
      url: `${process.env.VUE_APP_COUCH_URI}${username}_${collection.name}/`,
      push: {},
      pull: {},
      live: true,
      fetch: (url, options) => fetch(url, {
        ...options,
        credentials: `include`,
      }),
    },
  )

  replicationState.error$.subscribe(error => {
    if (error.code === `RC_STREAM`) {
      // happens on page load for Firefox without any real consequences, ignore for now
      return
    }

    /* When unauthorized, stop replication until user logs in again */
    const isUnauthorized = error?.parameters.errors?.some(error => error?.parameters?.args.jsonResponse.error === `unauthorized`)
    if (isUnauthorized) {
      console.info(`CouchDB returned 'unauthorized' error, stopping replication`)
      replicationStates.forEach(replicationState => replicationState.cancel())
      removeLocalSignedInUser()
      router.push({ name: Route.SignIn })
    } else {
      console.error(`Unhandled replication error:`, error)
    }
  })

  return replicationState
}

const startReplication = (username: string) => {
  replicationStates = Object.values(database.collections).map(collection => replicateCollection(collection, username))
}

const deleteLocalData = () => {
  return database.remove()
}

const getReplicationStatus = () => {

}

export {
  createDatabase,
  startReplication,
  deleteLocalData,
  getReplicationStatus,
}
