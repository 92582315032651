import {
  ExtractDocumentTypeFromTypedRxJsonSchema,
  toTypedRxJsonSchema,
  RxJsonSchema,
  RxDocument,
  RxCollection,
} from "rxdb"
import { ExpenseDocument } from "@/schemas/expense"

const schemaLiteral = {
  title: `Category schema`,
  version: 0,
  primaryKey: `id`,
  type: `object`,
  properties: {
    id: {
      type: `string`,
      maxLength: 36,
    },
    title: {
      type: `string`,
    },
    color: {
      type: `string`,
    },
  },
  required: [
    `id`,
    `title`,
    `color`,
  ],
} as const

const schemaTyped = toTypedRxJsonSchema(schemaLiteral)

export type Category = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>
export type CategoryMethods = {
  getExpenses: () => Promise<ExpenseDocument[]>,
  getExpenseCount: () => Promise<number>,
}
export type CategoryDocument = RxDocument<Category, CategoryMethods>
export type CategoryStatics = {
  getSortedCategories: () => Promise<CategoryDocument[]>
}
export type CategoryCollection = RxCollection<Category, CategoryMethods, CategoryStatics>
export default schemaLiteral as RxJsonSchema<Category>
