import chevronLeft from '@fortawesome/fontawesome-free/svgs/solid/chevron-left.svg'
import chevronRight from '@fortawesome/fontawesome-free/svgs/solid/chevron-right.svg'
import fileExport from '@fortawesome/fontawesome-free/svgs/solid/file-export.svg'
import fileLines from '@fortawesome/fontawesome-free/svgs/regular/file-lines.svg'
import house from '@fortawesome/fontawesome-free/svgs/solid/house.svg'
import logo from '@/assets/logo.svg'
import plus from '@fortawesome/fontawesome-free/svgs/solid/plus.svg'
import rightFromBracket from '@fortawesome/fontawesome-free/svgs/solid/right-from-bracket.svg'
import rightToBracket from '@fortawesome/fontawesome-free/svgs/solid/right-to-bracket.svg'
import spinner from '@fortawesome/fontawesome-free/svgs/solid/spinner.svg'
import tags from '@fortawesome/fontawesome-free/svgs/solid/tags.svg'
import trash from '@fortawesome/fontawesome-free/svgs/solid/trash.svg'
import section from '@fortawesome/fontawesome-free/svgs/solid/section.svg'

function readonlyMapWithStringKeys<K extends string, V> (iterable: Iterable<[K, V]>): ReadonlyMap<K, V> {
  return new Map(iterable)
}

export const iconMap = readonlyMapWithStringKeys([
  [`chevronLeft`, chevronLeft.id],
  [`chevronRight`, chevronRight.id],
  [`fileExport`, fileExport.id],
  [`fileLines`, fileLines.id],
  [`house`, house.id],
  [`logo`, logo.id],
  [`plus`, plus.id],
  [`rightFromBracket`, rightFromBracket.id],
  [`rightToBracket`, rightToBracket.id],
  [`spinner`, spinner.id],
  [`tags`, tags.id],
  [`trash`, trash.id],
  [`section`, section.id],
])

export type IconName = Parameters<typeof iconMap[`get`]>[0]
